<!--
 * @Author: yuwenwen
 * @Date: 2022-11-10 11:21:38
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-11-15 17:30:09
 * @FilePath: \nc-funeral-mobile\src\views\policy\details.vue
-->
<template>
  <div class="page-container page-container-100">
    <div class="policy">
      <div class="policy-article-title text-ellipse-2">
        {{policyDetails.titile}}
      </div>
      <div class="policy-article-datetime">{{policyDetails.sendTime}}</div>
      <div class="policy-article-content" v-html="policyDetails.msgContent"></div>
    </div>
  </div>
</template>
<script>
import {getNoticeDetails} from '@/api/index.js'
export default {
  name: "Policy",
  data() {
    return {
      policyId:'',
      policyDetails:{}
    };
  },
  created() {
    let {id} = this.$route.query;
    this.policyId = id;
    this.getNoticeDetails();
  },
  mounted() {},
  methods: {
   async getNoticeDetails(){
      let [err,res] = await this.$awaitWrap(getNoticeDetails(this.policyId));
      if(err){
        return ;
      }
      this.policyDetails = res.data;
      console.log(res);
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/index.less";
</style>